import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import { IQuiz } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz';
import { Button } from 'react-bootstrap';
import LessonContext from '../../../../LessonContext';
import shortEnglishHumanizer from 'src/helpers/shortEnglishHumanizer';
import { IUserLessonCardData } from '../../../UserLessonCard';
import PreviousAttempts from '../Components/PreviousAttempts';
import './Quiz.slide.scss';
import { isEmpty, isNil } from 'lodash';
import QuizHelper from '../QuizHelper';

export interface IProps {
    quiz: IQuiz;
    cardId: string;
    cardIndex: number;
    userLessonCardData: IUserLessonCardData;
    theme?: string;
    heading?: string;
    subHeading?: string;
    questionFileName?: string;
}

interface IState {
    message: any | null;
}

export default class slideQuiz extends Component<IProps, IState> {
    static readonly contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    state: IState = {
        message: null,
    };

    startNewQuiz = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Quiz in progress',
            body: 'You already have a quiz in progress, do you want to resume?',
            confirm: {
                text: 'Resume quiz',
                action: this.enterQuiz,
            },
            cancel: {
                text: 'Start new quiz',
                action: async () => {
                    await QuizHelper.getQuizConditionBasedStartQuizModal(
                        this.quizInProgress,
                        this.context.lesson?.unlockNextLesson,
                        this.handleCancel,
                    );
                },
            },
            dismiss: () => {},
        });
    };

    handleCancel = async () => {
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz/submit', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            quizInProgress: this.quizInProgress,
        });
        if (success) {
            if (response && !isEmpty(response.updatedCards) && this.context.updateUserLessonCards) {
                this.context.updateUserLessonCards(response.updatedCards);
            }
            if (this.context.setTooltip) this.context.setTooltip(response.tooltip);
            this.openQuiz();
        }
    };

    openQuiz = () => {
        EventBus.dispatch('enter-quiz', {
            cardId: this.props.cardId,
            cardIndex: this.props.cardIndex,
        });
    };

    enterQuiz = async () => {
        await QuizHelper.getQuizConditionBasedStartQuizModal(
            this.quizInProgress,
            this.context.lesson?.unlockNextLesson,
            this.openQuiz,
        );
    };

    get quizInProgress(): boolean {
        return !!this.props.userLessonCardData?.quiz?.attemptInProgress?.quiz;
    }

    get quiz(): IQuiz {
        return this.props?.userLessonCardData?.quiz?.attemptInProgress?.quiz ?? this.props.quiz;
    }

    isStartQuizBtnVisible = () => {
        const quiz = this.props.quiz;
        const previousAttempts = this.props.userLessonCardData?.quiz?.previousAttempts ?? [];
        const attemptPassed = this.props.userLessonCardData?.quiz?.quizPassed ?? false;
        if (previousAttempts.length > 0) {
            switch (quiz.allowReattempt) {
                case 'never':
                    return false;
                case 'always':
                    return true;
                case 'on_fail':
                    return !attemptPassed;
                default:
                    return false;
            }
        } else {
            return true;
        }
    };

    getNoOfQuestions = (subSetLength: number, questionLength: number) => {
        let count = subSetLength;
        if (count > questionLength || count === 0) {
            count = questionLength;
        }
        return count;
    };

    getChapterMasteryRequireInfo = () => {
        if (
            this.context.lesson?.unlockNextLesson.includes('CHAPTER_MASTERY_REQUIREMENT') &&
            (this.context.lesson?.timeSpentHistory ?? []).length > 0
        ) {
            const fullScoreCounts = (this.props.userLessonCardData?.quiz?.previousAttempts ?? []).filter(
                (quiz) => +(quiz?.percentageScore ?? 0) === 100,
            ).length;

            if (fullScoreCounts > 2) {
                return null;
            } else {
                return (
                    <p>
                        Pass attempts:&nbsp;
                        {fullScoreCounts}/2 Passed
                    </p>
                );
            }
        }

        return null;
    };

    render() {
        return (
            <div className='lesson-cards__endofchapter-quiz-type'>
                <div
                    className={`quiz__info ${
                        (isNil(this.props.quiz?.quizType) || this.props.quiz?.quizType === 'inline') &&
                        'quiz__unset-default-background'
                    }`}
                >
                    <h4>
                        <b>{this.quiz.title}</b>
                    </h4>
                    {this.state.message && (
                        <div className={`quiz__alert quiz__alert--${this.state.message.type}`}>
                            <h6>Passed</h6>
                            <span>{this.state.message.text}</span>
                        </div>
                    )}
                    <div className='quiz__footer'>
                        <div className='quiz__data'>
                            <p>
                                Number of questions:{' '}
                                {this.getNoOfQuestions(this.quiz.questionSubsetCount, this.quiz.questions.length)}
                            </p>
                            <p>Passing percentage: {this.quiz.passPercentage}%</p>
                            <p>
                                Time limit:&nbsp;
                                {shortEnglishHumanizer(this.quiz.timeLimit * 60000)}
                            </p>
                            {this.getChapterMasteryRequireInfo()}
                        </div>
                        {this.quizInProgress && (
                            <Button className='bp' onClick={this.enterQuiz}>
                                Resume quiz
                            </Button>
                        )}
                        {this.isStartQuizBtnVisible() && (
                            <Button className='bp' onClick={this.quizInProgress ? this.startNewQuiz : this.enterQuiz}>
                                Start {this.quizInProgress ? 'new ' : ''} quiz
                            </Button>
                        )}
                    </div>
                </div>
                {(!!this.props.userLessonCardData.quiz?.previousAttempts?.[0] ||
                    !!this.props.userLessonCardData.quiz?.beforeResetPreviousAttempts?.[0]) && (
                    <PreviousAttempts
                        cardId={this.props.cardId}
                        quizAttempts={this.props?.userLessonCardData?.quiz.previousAttempts}
                        beforeResetPreviousAttempts={
                            this.props?.userLessonCardData?.quiz.beforeResetPreviousAttempts ?? []
                        }
                        cardIndex={this.props.cardIndex}
                    />
                )}
            </div>
        );
    }
}
