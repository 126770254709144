import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import LessonContext from 'src/pages/Course/Stages/Lessons/LessonContext';
import Question from '../../Question/Question';
import { IQuiz } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz';
import { IQuizAttempt } from 'src/pages/Course/Stages/Lessons/LessonTypes/Slide/Quiz/Quiz';
import { Spinner } from 'src/components/Spinner';
import './Inline.quiz.scss';
import { Header } from '../../../../Components';
import { clone, compact, isEmpty, isNil } from 'lodash';
import { IUserLessonCardData } from '../../../../UserLessonCard';
import QuizHelper from '../../QuizHelper';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';

export interface IQuestion {
    correctOptionIndex: number;
    correctFeedback?: string;
    incorrectFeedback?: string;
    options: string[];
    title: string;
}

interface IProps extends RouteComponentProps {
    cardId: string;
    theme?: string;
    heading?: string;
    subHeading?: string;
    quiz: IQuiz;
    userLessonCardData: IUserLessonCardData;
}

interface IState {
    quiz: IQuiz;
    answers: (undefined | number)[];
    revealAnswers: boolean;
    isQuizInReview: boolean;
    attemptPassed: boolean | null;
    isQuizLoading: boolean;
}
class InlineQuiz extends Component<IProps, IState> {
    static readonly contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;

    async componentDidMount(): Promise<void> {
        if (this.props.userLessonCardData?.isAdminPreview) {
            this.loadQuiz(true);
        } else {
            this.loadQuiz();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (
            JSON.stringify(prevProps.quiz) !== JSON.stringify(this.props.quiz) &&
            this.props.userLessonCardData?.isAdminPreview
        ) {
            this.loadQuiz(true);
        }
    }

    loadQuiz = async (isRetry = false) => {
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            isDraft: this.context.isDraft,
            isInline: true,
        });

        if (!success) return;

        this.setQuizData(response, isRetry);
    };

    setQuizData = (response: any, isRetry = false) => {
        const { attemptInProgress, previousAttempts = [] } = response;

        if (previousAttempts.length === 0 || !!attemptInProgress?.startedAt || isRetry) {
            this.setState({
                quiz: attemptInProgress.quiz,
                answers: attemptInProgress.answers,
                revealAnswers: false,
                isQuizInReview: false,
                attemptPassed: null,
            });
        } else {
            const attempt = previousAttempts[previousAttempts.length - 1] as IQuizAttempt;
            let revealAnswers;

            switch (attempt.quiz.revealAnswers) {
                case 'always':
                    revealAnswers = true;
                    break;
                case 'never':
                    revealAnswers = false;
                    break;
                case 'on_pass':
                    revealAnswers = attempt.status === 'SUCCESS';
                    break;
                default:
                    revealAnswers = false;
                    break;
            }

            this.setState({
                quiz: attempt.quiz,
                answers: attempt.answers,
                revealAnswers,
                isQuizInReview: true,
                attemptPassed: attempt.status === 'SUCCESS',
            });
        }
    };

    handleAnswer = async (answer: number, questionIndex: number) => {
        const currentValue = clone(this.state.answers)[questionIndex];
        this.setState((prevState: IState) => {
            const answers = prevState.answers;
            answers[questionIndex] = answer;
            return { answers };
        });

        const { success, message } = await Api.call('post', 'users/lessoncards/quiz/answer', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            questionIndex,
            userAnswerIndex: answer,
        });

        if (!success) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: message ?? 'Something wrong on answer selection !',
            });

            const answers = clone(this.state.answers);
            if (!isNil(currentValue)) {
                answers[questionIndex] = currentValue;
            } else {
                delete answers[questionIndex];
            }

            this.setState({ answers });
        }
    };

    handleSubmit = async () => {
        const answers = compact(this.state.answers.map((v) => !isNil(v)));

        const questionsSkipped = this.state.quiz.questions.length !== answers.length;
        if (questionsSkipped) {
            EventBus.dispatch('confirmation-popup', {
                title: "You've skipped questions",
                body: 'You have skipped some questions, please answer all of them in order to submit your attempt',
                confirm: {
                    text: 'Will do!',
                    action: () => {},
                },
                cancel: null,
                dismiss: () => {},
            });
        } else {
            this.submitQuiz();
        }
    };

    submitQuiz = async () => {
        this.setState({ isQuizLoading: true });
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz/submit', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            quizInProgress: false,
        });

        if (success) {
            if (response && !isEmpty(response.updatedCards) && this.context.updateUserLessonCards) {
                this.context.updateUserLessonCards(response.updatedCards);
            }
            if (this.context.setTooltip) {
                this.context.setTooltip(response.tooltip);
            }

            if (response?.submitedQuiz) {
                this.setQuizData(response.submitedQuiz);
            }
            if (response.reload) {
                const modalAction = async () => {
                    const newLocation = `/courses/${this.context.lesson.userCourseId}/chapters/${response.lastChapterId}/lessons/${response.lastLessonId}`;
                    localStorage.setItem(`${this.context.lesson.userCourseId}-manual-reload`, 'true');
                    this.context.syncCourseData(this.context.lesson.userCourseId);

                    this.props.navigate(newLocation, {
                        state: {
                            avoidRouteLeavingGuard: true,
                        },
                    });
                };

                const downloadQuizFeedback = async () => {
                    if (response.submitedQuiz?.previousAttempts?.length) {
                        QuizHelper.downloadQuizResult(
                            response.submitedQuiz?.previousAttempts[
                                response.submitedQuiz?.previousAttempts?.length - 1
                            ],
                        );
                    }
                };

                EventBus.dispatch('action-confirmation-popup', {
                    title: response?.popupContent?.title ?? `Quiz attempt failed!`,
                    body: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    response?.popupContent?.description.join('') ??
                                    `You have failed this quiz and you will have to re-take the chapter lessons again!`,
                            }}
                        />
                    ),
                    buttons: [
                        {
                            text: `I understand, download my quiz feedback`,
                            action: downloadQuizFeedback,
                            clz: 'btn',
                        },
                    ],
                    className: `blue-header-popup`,
                    dismiss: () => {},
                });
                await modalAction();
            }
        }

        if (!(response?.reload ?? false)) {
            EventBus.dispatch('re-enter-lesson');
        }
        this.setState({ isQuizLoading: false });
    };

    retry = () => {
        this.loadQuiz(true);
    };

    get userCanRetry() {
        const { quiz, attemptPassed } = this.state;

        if (attemptPassed === null) return false;

        switch (quiz.allowReattempt) {
            case 'never':
                return false;
            case 'always':
                return true;
            case 'on_fail':
                return !attemptPassed;
            default:
                return false;
        }
    }

    render() {
        if (!this?.state?.quiz) {
            return <Spinner />;
        }

        const { quiz, answers, revealAnswers, isQuizInReview, isQuizLoading } = this.state;
        const { heading, subHeading } = this.props;

        return (
            <div className='inline-quiz'>
                {(heading || subHeading) && <Header heading={heading} subHeading={subHeading} />}
                {isQuizLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <h4 className='quiz-title'>{quiz.title}</h4>
                        <div className='questions'>
                            {quiz.questions.map((question: IQuestion, index: number) => {
                                return (
                                    <Question
                                        key={index}
                                        questionIndex={index}
                                        question={question}
                                        answer={answers[index]}
                                        handleAnswer={this.handleAnswer}
                                        revealAnswers={revealAnswers}
                                        isQuizInReview={isQuizInReview}
                                    />
                                );
                            })}
                        </div>
                        {!isQuizInReview ? (
                            <button className='btn bp' onClick={this.handleSubmit}>
                                Submit
                            </button>
                        ) : this.userCanRetry ? (
                            <button className='btn bp' onClick={this.retry}>
                                Retry
                            </button>
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(InlineQuiz);
