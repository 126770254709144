import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './ActionConfirmationHandler.scss';
import { isNil } from 'lodash';
import ConditionalWrapper from 'src/components/ConditionalWrapper';

type syncAsyncFunction = ((...args: any) => any) | ((...args: any) => Promise<any>);

interface IButton {
    text?: string;
    action: syncAsyncFunction;
    clz?: string;
}
export interface IConfirationPopup {
    title?: string | any[];
    body?: string | any[];
    doAsyncInBackground?: boolean;
    confirm: {
        text?: string;
        action: syncAsyncFunction;
        clz?: string;
    } | null;
    cancel?: {
        text?: string;
        action?: syncAsyncFunction;
        clz?: string;
        isDisabled?: boolean;
        tooltipText: string;
    } | null;
    dismiss?: syncAsyncFunction;
    buttons?: IButton[];
    className?: string;
    backdrop?: boolean;
}

interface IState {
    modalOpen: boolean;
    modalDetails?: IConfirationPopup;
}

export default class ActionConfirmationHandler extends Component<unknown, IState> {
    state: IState = {
        modalOpen: false,
    };

    componentDidMount(): void {
        EventBus.on('action-confirmation-popup', this.createConfirmationPopup);
        EventBus.on('action-confirmation-popup-close', this.closeModal);
    }

    componentWillUnmount(): void {
        this.setState({ modalDetails: undefined });
        EventBus.remove('action-confirmation-popup', this.createConfirmationPopup);
        EventBus.remove('action-confirmation-popup-close', this.closeModal);
    }

    createConfirmationPopup = (e: Event) => {
        const modalDetails: IConfirationPopup = (e as CustomEvent).detail;

        this.setState({ modalOpen: true, modalDetails });
    };

    confirm = async () => {
        if (this.state.modalDetails?.doAsyncInBackground) {
            this.closeModal();
            await this.state.modalDetails?.confirm?.action();
        } else {
            await this.state.modalDetails?.confirm?.action();
            this.closeModal();
        }
    };

    cancel = async () => {
        await this.state.modalDetails?.cancel?.action?.();
        this.closeModal();
    };

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    dismiss = async () => {
        if (this.state.modalDetails?.doAsyncInBackground) {
            this.closeModal();
            await this.onDismissHandler();
        } else {
            await this.onDismissHandler();
            this.closeModal();
        }
    };

    onDismissHandler = async () => {
        if (this.state?.modalDetails?.dismiss) {
            await this.state.modalDetails.dismiss();
        } else {
            await this.state.modalDetails?.cancel?.action?.();
        }
    };

    buttonClick = async (buttonDetails: any) => {
        if (this.state.modalDetails?.doAsyncInBackground) {
            this.closeModal();
            await buttonDetails.action();
        } else {
            await buttonDetails.action();
            this.closeModal();
        }
    };

    render() {
        const { modalOpen, modalDetails } = this.state;
        const { title, body, cancel, confirm, buttons, className, backdrop = true } = modalDetails ?? {};

        return (
            <Modal
                id='action-confirmation-popup'
                show={modalOpen}
                onHide={this.dismiss ? this.dismiss : this.cancel}
                className={className}
                backdrop={backdrop}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div>{title}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{body}</div>
                </Modal.Body>
                {!isNil(buttons) || !isNil(confirm) || !isNil(cancel) ? (
                    <Modal.Footer>
                        {!isNil(buttons) &&
                            buttons?.map((val, idx) => {
                                return (
                                    <Button
                                        key={`btn--${idx}`}
                                        variant='primary'
                                        onClick={(e) => this.buttonClick(val)}
                                        className={val?.clz}
                                    >
                                        {val?.text}
                                    </Button>
                                );
                            })}
                        {!isNil(confirm) && (
                            <Button variant='primary' onClick={this.confirm} className={confirm?.clz}>
                                {confirm?.text ?? 'Confirm'}
                            </Button>
                        )}
                        {!isNil(cancel) && (
                            <ConditionalWrapper
                                condition={cancel.isDisabled}
                                wrapper={(children: any) => (
                                    <OverlayTrigger
                                        overlay={<Tooltip id={`cancel-tooltip-text`}> {cancel.tooltipText}</Tooltip>}
                                    >
                                        {children}
                                    </OverlayTrigger>
                                )}
                            >
                                <Button variant='btn bd' onClick={this.cancel} className={cancel?.clz}>
                                    {cancel?.text ?? 'Cancel'}
                                </Button>
                            </ConditionalWrapper>
                        )}
                    </Modal.Footer>
                ) : null}
            </Modal>
        );
    }
}
