import React from 'react';
import { jsPDF } from 'jspdf';
import humanizeDuration from 'humanize-duration';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { isEmpty, some } from 'lodash';
import { EventBus } from 'src/helpers/new';

export default class QuizHelper {
    static readonly getAdditionalOptionInfo = (userAnswer: number, correctOptionIndex: number, optionIndex: number) => {
        let additionalColor = '';
        let additionalText = '';

        if (optionIndex === userAnswer) {
            if (userAnswer === correctOptionIndex) {
                additionalText = 'Your answer was correct';
                additionalColor = '#2fcca1';
            }

            if (userAnswer !== correctOptionIndex) {
                additionalText = 'Your answer was incorrect';
                additionalColor = '#ffbb69';
            }
        }

        if (optionIndex === correctOptionIndex && optionIndex !== userAnswer /**&this.props.revealAnswers === true*/) {
            additionalText = 'The correct answer';
            additionalColor = '#eaecef';
        }

        return { additionalText, additionalColor };
    };

    static async downloadQuizResult(attempt: any) {
        const doc = new jsPDF('p', 'px', 'a4'),
            maxWidth = doc.internal.pageSize.getWidth(),
            maxHeight = doc.internal.pageSize.getHeight(),
            baseUnit = 9,
            cCyan = '#f5fafd',
            cGreen = '#2fcca1',
            cOrange = '#ffbb69',
            cWhite = '#fff',
            cBlack = '#000',
            correctAnswers = attempt.answers.reduce((count: number, val: number, index: number) => {
                if (attempt.quiz.questions[index].correctOptionIndex === val) {
                    count++;
                }
                return count;
            }, 0),
            resultText = `You have scored ${Math.round(
                (correctAnswers / attempt.quiz.questions.length) * 100,
            )} % (${correctAnswers}/${attempt.quiz.questions.length}) in ${humanizeDuration(
                DateTimeFormatHelper.diff(
                    DateTimeFormatHelper.getDate(attempt?.completedAt),
                    DateTimeFormatHelper.getDate(attempt?.startedAt),
                ),
                {
                    round: true,
                },
            )}`;

        //rect upper most
        doc.setFillColor(cCyan);
        doc.rect(0, 0, maxWidth, baseUnit * 3.6, 'F');

        doc.setFontSize(baseUnit * 1.4);
        doc.setFont('helvetica', 'bold');
        doc.text(`Attempt #${attempt._id.toUpperCase()} answers`, baseUnit * 1.2, baseUnit * 2.1);

        doc.setFontSize(baseUnit);
        doc.setFont('helvetica', 'normal');

        const { w: wResult } = doc.getTextDimensions(resultText);

        doc.text(resultText, maxWidth - wResult - baseUnit * 1.2, baseUnit * 2.1);

        let yAxis = baseUnit * 5.6;

        for (const questionIndex in attempt.quiz.questions) {
            doc.setFontSize(baseUnit * 1.2);
            const question = attempt.quiz.questions[questionIndex],
                text = doc.splitTextToSize(
                    `${parseInt(questionIndex) + 1}. ${question.title}`,
                    maxWidth - baseUnit * 6,
                ),
                { h } = doc.getTextDimensions(text);

            doc.setFont('helvetica', 'bold');
            doc.text(text, baseUnit * 1.2, yAxis);
            yAxis += h + baseUnit * 1.2;
            for (const optionIndex in question.options) {
                let additionalHeight = 0;
                doc.setFontSize(baseUnit * 1);
                const text = doc.splitTextToSize(question.options[optionIndex], maxWidth - baseUnit * 6);

                const { h, w: wOptionText } = doc.getTextDimensions(text);

                const { additionalText, additionalColor } = QuizHelper.getAdditionalOptionInfo(
                    attempt.answers[questionIndex],
                    question.correctOptionIndex,
                    parseInt(optionIndex),
                );

                if (additionalText) {
                    doc.setFillColor(additionalColor);
                    const { h: hAdditionalText, w: wAddtionalText } = doc.getTextDimensions(additionalText);

                    const wTotal = wAddtionalText + wOptionText + baseUnit * 5.4;

                    if (wTotal > maxWidth) {
                        let heightToAdd = baseUnit;
                        if (h === hAdditionalText) {
                            heightToAdd = baseUnit * 2;
                        }
                        doc.rect(baseUnit, yAxis - 10, maxWidth - baseUnit * 2, h + hAdditionalText + heightToAdd, 'F');

                        doc.setFont('helvetica', 'bold');
                        doc.text(
                            additionalText,
                            maxWidth - baseUnit * 2.4 - wAddtionalText,
                            yAxis + hAdditionalText + baseUnit,
                        );
                        additionalHeight += hAdditionalText + heightToAdd;
                    } else {
                        doc.rect(baseUnit, yAxis - 10, maxWidth - baseUnit * 2, h + 9, 'F');

                        doc.setFont('helvetica', 'bold');
                        doc.text(additionalText, maxWidth - baseUnit * 2.4 - wAddtionalText, yAxis);

                        additionalHeight += baseUnit;
                    }
                } else {
                    additionalHeight += baseUnit;
                }

                // set the colors & line width for the "checkbox"
                doc.setFillColor(cWhite);
                doc.setDrawColor(cBlack);
                doc.setLineWidth(0.6);
                doc.circle(baseUnit * 2, yAxis - 2.5, baseUnit * 0.36, 'DF');
                // mark the selected option
                if (attempt.answers[questionIndex] == optionIndex) {
                    doc.setFillColor(cBlack);
                    doc.circle(baseUnit * 2, yAxis - 2.5, baseUnit * 0.22, 'F');
                }
                // type the option
                doc.setFont('helvetica', 'normal');
                doc.text(text, baseUnit * 3, yAxis);
                yAxis += h + additionalHeight;
            }

            // add feedback message
            const message = doc.splitTextToSize(
                    attempt.answers[questionIndex] === question.correctOptionIndex
                        ? question.correctFeedback
                        : question.incorrectFeedback,
                    maxWidth - baseUnit * 2.7,
                ),
                { h: h2 } = doc.getTextDimensions(message),
                hTotal = h2 + baseUnit * 2;

            if (!isEmpty(message) && some(message, (value: any) => Boolean(value))) {
                doc.setFillColor(cCyan);
                doc.rect(baseUnit, yAxis, maxWidth - baseUnit * 2, hTotal, 'F');
                doc.setFillColor(attempt.answers[questionIndex] === question.correctOptionIndex ? cGreen : cOrange);
                doc.rect(baseUnit, yAxis, 3, hTotal, 'F');
                doc.setFont('helvetica', 'normal');
                doc.text(message, baseUnit * 1.4 + 3, yAxis + baseUnit * 1.8);
                yAxis += hTotal + baseUnit * 2;
            }

            // code for add new page in next question there
            if (yAxis > maxHeight - 140 && parseInt(questionIndex) !== attempt.quiz.questions.length - 1) {
                yAxis = 20;
                doc.addPage();
            }
        }

        doc.save(`attempt-${attempt._id}.pdf`);
    }

    static async getQuizConditionBasedStartQuizModal(
        isQuizInProcess: boolean,
        quizUnlockCondition: string,
        action: () => void,
    ) {
        if (isQuizInProcess) {
            action();
        } else if (quizUnlockCondition?.includes('CHAPTER_MASTERY_REQUIREMENT')) {
            EventBus.dispatch('action-confirmation-popup', {
                title: 'Start chapter quiz',
                body: [
                    <p key='param 1'>
                        {`This quiz tests your knowledge on everything you've covered in this chapter. It is mandatory to take and answer 100% of the questions in the chapter quiz correctly. If any questions are answered incorrectly, you will need to retake the entire chapter from the beginning before being able to try again—please ensure you are prepared to answer the questions correctly.`}
                    </p>,
                    <p key='para 2'>
                        {`If you are already reattempting the chapter quiz after a previous unsuccessful attempt, you will not need to retake the entire chapter for a third time. You will, however, be required to successfully complete the chapter quiz itself twice before moving on.`}
                    </p>,
                    <p key='para 3'>{`Are you sure you'd like to take the chapter quiz now?`}</p>,
                ],
                confirm: {
                    text: 'Start new quiz',
                    action: action,
                },
                cancel: {
                    text: 'Go back',
                    action: () => {},
                },
                className: `blue-header-popup`,
                dismiss: () => {},
            });
        } else if (quizUnlockCondition.includes('ON_FAIL_REVISIT_CHAPTER')) {
            EventBus.dispatch('action-confirmation-popup', {
                title: 'Start chapter quiz',
                body: [
                    <p key='param 1'>
                        {`This quiz tests your knowledge on everything you've covered in this chapter. It is mandatory to
                    take and pass the chapter quiz. If the chapter quiz is failed, you will need to retake the entire
                    chapter from the beginning before being able to try again—please ensure you are prepared to answer
                    the questions correctly.`}
                    </p>,
                    <p key='para 2'>{`Are you sure you'd like to take the chapter quiz now?`}</p>,
                ],
                confirm: {
                    text: 'Start new quiz',
                    action: action,
                },
                cancel: {
                    text: 'Go back',
                    action: () => {},
                },
                className: `blue-header-popup`,
                dismiss: () => {},
            });
        } else {
            action();
        }
    }
}
