import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import LessonContext from '../../../LessonContext';
import { Spinner } from 'src/components/Spinner';
import { Api, EventBus } from 'src/helpers/new';
import Header from '../Header';
import Navigation from './QuizNavigation';
import CardsQuiz from './CardsQuiz';
import { IQuiz } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz';
import { isEmpty, isNil, clone } from 'lodash';
import { RouteLeavingGuard } from 'src/components/RouteLeavingGuard';
import QuizHelper from '../../../Cards/Interactive/Quiz/QuizHelper';

interface IRouteProps {
    courseId: string;
    lessonId: string;
}

interface IProps {
    course: any;
    cardId: string;
    attemptId?: string;
    withHeader?: boolean;
}

interface IState {
    activeQuestionCardIndex: number;
    quiz: {
        _id: string;
        attemptInProgress: IQuizAttempt;
        previousAttempts: IQuizAttempt[];
        beforeResetPreviousAttempts?: IQuizAttempt[];
    };
    cardId?: string;
    isLoading: boolean;
    isQuizLoading: boolean;
    isDirty: boolean;
}

export interface IQuizAttempt {
    _id: string;
    quiz: IUserQuiz;
    answers: (undefined | number)[];
    startedAt: Date;
    completedAt?: Date;
    status?: 'SUCCESS' | 'FAIL';
    score?: number;
    percentageScore?: number;
    remainingTime?: number;
}

interface IUserQuiz extends IQuiz {
    quizPassed?: boolean;
    uspapQuiz: boolean;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

class Quiz extends Component<TProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;

    state: IState = {
        activeQuestionCardIndex: 0,
        isQuizLoading: false,
        quiz: {
            _id: '',
            attemptInProgress: {
                _id: '',
                answers: [],
                quiz: {
                    title: '',
                    questions: [],
                    quizType: 'inline',
                    timeLimit: 0,
                    passPercentage: 0,
                    randomize: true,
                    revealAnswers: 'always',
                    allowReattempt: 'always',
                    allowSkip: true,
                    questionSubsetCount: 0,
                    uspapQuiz: false,
                },
                startedAt: new Date(),
                remainingTime: 0,
            },
            previousAttempts: [],
        },
        isLoading: true,
        isDirty: true,
    };
    themeRef: any = React.createRef();

    componentDidMount() {
        if (this.props.attemptId) this.loadQuizAttempt();
        else this.loadQuiz();
    }

    loadQuiz = async () => {
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            isDraft: this.context.isDraft,
        });
        if (!success) return;

        if (response.attemptInProgress) {
            if (response?.attemptInProgress?.remainingTime && response?.attemptInProgress?.remainingTime <= 0) {
                EventBus.dispatch('confirmation-popup', {
                    title: 'Out of time',
                    body: 'You have run out of time for this quiz. Your answers have been submitted',
                    doAsyncInBackground: true,
                    confirm: {
                        text: 'Okay',
                        action: () => {},
                    },
                });
                this.submitQuiz();
            }
        }

        this.setState({ quiz: response, isLoading: false, activeQuestionCardIndex: 0 });
    };

    loadQuizAttempt = async () => {
        const { success, response } = await Api.call('post', `users/lessoncards/quiz/attempt/${this.props.attemptId}`, {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
        });
        if (!success) return;

        const { quiz } = this.state;
        quiz.attemptInProgress = response;

        this.setState({ quiz, isLoading: false, activeQuestionCardIndex: 0 });
    };

    setActiveCardIndex = async (index: number) => {
        this.setState({
            activeQuestionCardIndex: index,
        });
    };

    answerQuestion = async (questionIndex: number, userAnswerIndex: number) => {
        if (!!this.props.attemptId) return;

        const quiz = { ...this.state.quiz };
        const currentValue = clone(quiz.attemptInProgress.answers)[questionIndex];
        quiz.attemptInProgress.answers[questionIndex] = userAnswerIndex;
        this.setState({ quiz });

        const { success, message } = await Api.call('post', 'users/lessoncards/quiz/answer', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            questionIndex,
            userAnswerIndex,
        });

        if (!success) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: message ?? 'Something wrong on answer selection !',
            });

            if (!isNil(currentValue)) {
                quiz.attemptInProgress.answers[questionIndex] = currentValue;
            } else {
                delete quiz.attemptInProgress.answers[questionIndex];
            }
            this.setState({ quiz });
        }
    };

    handlePause = async () => {
        EventBus.dispatch('leave-quiz');
    };

    handleSubmit = async () => {
        this.submitQuiz();
    };

    submitQuiz = async () => {
        this.setState({ isQuizLoading: true });
        const { success, response } = await Api.call('post', 'users/lessoncards/quiz/submit', {
            userLessonId: this.context.lesson._id,
            cardId: this.props.cardId,
            quizInProgress: false,
        });

        if (success) {
            if (response && !isEmpty(response.updatedCards) && this.context.updateUserLessonCards) {
                this.context.updateUserLessonCards(response.updatedCards);
            }
            if (this.context.setTooltip) {
                this.context.setTooltip(response.tooltip);
            }

            if (response.reload) {
                const modalAction = async () => {
                    const newLocation = `/courses/${this.context.lesson.userCourseId}/chapters/${response.lastChapterId}/lessons/${response.lastLessonId}`;
                    localStorage.setItem(`${this.context.lesson.userCourseId}-manual-reload`, 'true');
                    this.context.syncCourseData(this.context.lesson.userCourseId);
                    this.props.navigate(newLocation, {
                        state: {
                            avoidRouteLeavingGuard: true,
                        },
                    });
                    EventBus.dispatch('leave-quiz');
                };

                const downloadQuizFeedback = async () => {
                    if (response.submitedQuiz?.previousAttempts?.length) {
                        QuizHelper.downloadQuizResult(
                            response.submitedQuiz?.previousAttempts[
                                response.submitedQuiz?.previousAttempts?.length - 1
                            ],
                        );
                    }
                };

                EventBus.dispatch('action-confirmation-popup', {
                    title: response?.popupContent?.title ?? `Quiz attempt failed!`,
                    doAsyncInBackground: false,
                    body: (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: response?.popupContent?.description?.join(''),
                            }}
                        />
                    ),
                    buttons: [
                        {
                            text: `I understand, download my quiz feedback`,
                            action: downloadQuizFeedback,
                            clz: 'btn',
                        },
                    ],
                    className: `blue-header-popup`,
                    dismiss: () => {},
                });
                await modalAction();
            } else {
                EventBus.dispatch('leave-quiz');
            }
        }

        if (!(response?.reload ?? false)) {
            EventBus.dispatch('re-enter-lesson');
        }
        this.setState({ isQuizLoading: false });
    };

    get chapterName(): string {
        const { chapter } = this.context;
        return chapter && chapter.length > 0 ? chapter[0]?.doc?.title : '';
    }

    get quizProgress(): number {
        const quiz = this.state.quiz.attemptInProgress.quiz;

        return ((this.state.activeQuestionCardIndex + 1) * 100) / quiz?.questions?.length;
    }

    render() {
        if (this.state.isLoading) return <Spinner />;
        const { course } = this.props;
        const { title, lessonType, _id, requiredTime } = this.context.lesson;
        const inlineContent = (
            <>
                <RouteLeavingGuard when={this.state.isDirty} inQuiz={true} />
                <main ref={this.themeRef}>
                    <div className={`lesson-card ${course.lessonType} card-quiz`}>
                        {this.state.isQuizLoading ? (
                            <div className='quiz-spinner'>
                                <Spinner />
                            </div>
                        ) : (
                            <CardsQuiz
                                userQuiz={this.state.quiz.attemptInProgress}
                                onAnswer={this.answerQuestion}
                                forceSubmit={this.submitQuiz}
                                activeCardIndex={this.state.activeQuestionCardIndex}
                                isReview={!!this.props.attemptId}
                            />
                        )}
                    </div>
                </main>

                <footer>
                    <Navigation
                        course={course}
                        activeCardIndex={this.state.activeQuestionCardIndex}
                        setActiveCardIndex={this.setActiveCardIndex}
                        questions={this.state.quiz.attemptInProgress.quiz.questions}
                        answers={this.state.quiz.attemptInProgress.answers}
                        handleSubmit={this.handleSubmit}
                        isReview={!!this.props.attemptId}
                        allowSkip={this.state.quiz.attemptInProgress.quiz.allowSkip}
                        isQuizLoading={this.state.isQuizLoading}
                        isExam={false}
                    />
                    <div className='slide-container__progress'>
                        <div style={{ width: `${this.quizProgress}%` }}></div>
                    </div>
                </footer>
            </>
        );

        return this.props.withHeader ? (
            <div className='slide-container'>
                <div
                    className={`slide-container__wrapper ${
                        this.state.quiz.attemptInProgress?.quiz.quizType === 'slide' &&
                        'slide-container__unset-theme-background'
                    }`}
                >
                    <Header
                        lessonName={title}
                        lessonType={lessonType}
                        chapterName={this.chapterName}
                        courseTitle={course.title}
                        lessonId={_id}
                        requiredTime={requiredTime}
                    />
                    {inlineContent}
                </div>
            </div>
        ) : (
            inlineContent
        );
    }
}
export default withRouter(Quiz);
