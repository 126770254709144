import React, { Component } from 'react';
import CourseContext from 'src/pages/Course/CourseContext';
import BioSig from './BioSig';
import { EventBus } from 'src/helpers/new';
import './Proctoring.scss';
import { loadBiosightScript } from 'src/helpers/externalScript';
import { ProctoringFlagWarningDuration } from 'src/helpers/GlobalConstants';
import { ILoggedIn, IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

type syncAsyncFunction = (() => void) | (() => Promise<void>);

interface IState {
    requiresAuth: boolean;
    authCallback: syncAsyncFunction;
    stage?: string;
    isCheckPoint?: boolean;
    examProctoring: boolean;
    success: boolean;
    isResuming?: boolean;

    isWarningShownOnce: boolean;
}

interface IProps extends ILoggedIn {
    redirectToExamSummary: () => void;
}

class Proctoring extends Component<IProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    bioSightClient: any = null;
    failStatusCheckInterval: ReturnType<typeof setInterval> | null = null;

    state: IState = {
        requiresAuth: false,
        authCallback: () => {},
        examProctoring: false,
        success: false,
        isResuming: false,

        isWarningShownOnce: false,
    };

    componentDidMount() {
        window.socket.on('user-verification-success', this.authSuccess);
        window.socket.on('user-verification-fail', this.authFail);
        EventBus.on('require-auth', this.requireAuth as any);
        EventBus.on('start-exam-proctoring', this.startExamProctoring as any);
        EventBus.on('end-exam-proctoring', this.endExamProctoring as any);
    }

    componentWillUnmount() {
        this.clearFailStatusCheckInterval();
        window.socket.off('user-verification-success', this.authSuccess);
        window.socket.off('user-verification-fail', this.authFail);
        EventBus.remove('require-auth', this.requireAuth as any);
        EventBus.remove('start-exam-proctoring', this.startExamProctoring as any);
        EventBus.remove('end-exam-proctoring', this.endExamProctoring as any);
    }

    clearFailStatusCheckInterval = () => {
        if (this.failStatusCheckInterval) {
            clearInterval(this.failStatusCheckInterval);
            this.failStatusCheckInterval = null;
        }
    };

    requireAuth = (event?: Event) => {
        if (!this.context.course.bioSigAuthRequire) {
            const { callback } = (event as CustomEvent).detail;
            if (callback) {
                callback();
            }
        } else {
            const { stage, callback, isCheckPoint, isResuming } = (event as CustomEvent).detail;
            console.log('require biosig auth - ', { stage, isCheckPoint });
            this.setState({ requiresAuth: true, stage, isCheckPoint, authCallback: callback, isResuming });
        }
    };

    startExamProctoring = (event?: Event) => {
        console.log('attempt start exam proctoring');
        const { callback } = (event as CustomEvent).detail;

        // @ts-ignore
        if (typeof biosightclient === 'undefined') {
            loadBiosightScript();
        }
        this.setState({ examProctoring: true, requiresAuth: true, stage: 'exam', authCallback: callback });
    };

    endExamProctoring = () => {
        if (this.bioSightClient && this.bioSightClient?.isConnected) {
            this.bioSightClient.isConnected = false;
            this.bioSightClient.stop();
        }
        this.setState({ examProctoring: false });
    };

    authSuccess = (stagInfo: { nmlsStag: string; examId?: string; enrolledBioSigTypes?: string[] }) => {
        if (stagInfo.enrolledBioSigTypes?.length !== this.props.loggedIn?.user?.enrolledBioSigTypes?.length) {
            this.props.setLoggedIn({
                ...this.props.loggedIn,
                user: {
                    ...this.props.loggedIn.user,
                    enrolledBioSigTypes: stagInfo.enrolledBioSigTypes ?? [],
                },
            });
        }
        if (this.context.updateCourseInfo) {
            this.context.updateCourseInfo('nmlsStag', stagInfo.nmlsStag);
        }

        if (this.state.examProctoring) {
            console.log('attempt start proctoring');
            this.waitForBioSight(stagInfo.examId);
            this.setState({ requiresAuth: false, success: true, stage: undefined });
        } else {
            console.log('not in exam');
            EventBus.dispatch('bio-sig-auth-success', {
                isCheckPoint: this.state.isCheckPoint ?? false,
                isResuming: this.state.isResuming ?? false,
            });
            this.setState({ requiresAuth: false, success: true }, () => {
                this.state?.authCallback?.();
                this.setState({ authCallback: () => {}, stage: undefined });
            });
        }
    };

    authFail = (stagInfo: { nmlsStag: string; examId?: string; enrolledBioSigTypes?: string[] }) => {
        if (stagInfo.enrolledBioSigTypes?.length !== this.props.loggedIn?.user?.enrolledBioSigTypes?.length) {
            this.props.setLoggedIn({
                ...this.props.loggedIn,
                user: {
                    ...this.props.loggedIn.user,
                    enrolledBioSigTypes: stagInfo.enrolledBioSigTypes ?? [],
                },
            });
        }

        EventBus.dispatch('confirmation-popup', {
            title: 'Authentication failed',
            body: 'Authentication failed due to maximum failed password attempts, please try again or reset password by resuming exam again from exam summary!',
            confirm: {
                text: 'OK',
                action: () => {
                    this.props.redirectToExamSummary();
                    this.setState({ requiresAuth: false, success: false, stage: undefined });
                },
            },
            cancel: null,
            dismiss: () => {
                this.props.redirectToExamSummary();
                this.setState({ requiresAuth: false, success: false, stage: undefined });
            },
        });
    };

    waitForBioSight = (examId?: string) => {
        console.log('------ inside waitForBioSight');
        // biosightclient is located in an external js file imported via script tag
        // @ts-ignore
        if (typeof biosightclient !== 'undefined') {
            // @ts-ignore
            this.biosight(examId);
        } else {
            console.log('biosight client not loaded');
            setTimeout(this.waitForBioSight, 100);
        }
    };

    showProctoringIssueDetectedWarning = () => {
        this.setState(
            {
                isWarningShownOnce: true,
            },
            () => {
                this.clearFailStatusCheckInterval();
                EventBus.dispatch('action-confirmation-popup', {
                    className: 'action-confirmation-popup-flagged-since-long-time',
                    title: [
                        <div key='title'>
                            <b>Proctoring issues detected</b>
                        </div>,
                    ],
                    body: [
                        <div key='main_para' id='main_para'>
                            Our proctoring service has detected issues with your webcam/microphone feed. Please try to
                            maintain a consistent presence during your exam.
                        </div>,
                        <div key='common_issues_para' id='common_issues_para'>
                            Common issues are:
                        </div>,
                        <ul key='list' id='list'>
                            <li>Not being visible to webcam, or leaving the frame</li>
                            <li>Other people entering the webcam frame too often</li>
                            <li>Other people talking near your webcam or microphone</li>
                            <li>Being distracted by things off-screen</li>
                        </ul>,
                    ],
                    buttons: [
                        {
                            text: `Okay`,
                            action: async () => {},
                            clz: 'btn',
                        },
                    ],
                    backdrop: false,
                });
            },
        );
    };

    biosightFailStatusHandler = (latestStatus: { status: string }, self: any) => {
        EventBus.dispatch('biosight-flag');

        if (this.bioSightClient && this.bioSightClient?.isConnected && !this.state.isWarningShownOnce) {
            const currentMilliseconds = Date.now();

            console.log(
                `current status: flag ${latestStatus?.status} --last status:: ${this.bioSightClient.lastEvent}`,
            );

            if (!this.failStatusCheckInterval) {
                this.failStatusCheckInterval = setInterval(() => {
                    this.biosightFailStatusHandler({ status: 'infraction' }, self);
                }, 5000);
            }

            if (this.bioSightClient.lastEvent && this.bioSightClient.lastEvent === 'flag') {
                const difference = currentMilliseconds - this.bioSightClient.lastEventTime;
                const flagEventsDuration = (this.bioSightClient.flagEventsDuration ?? 0) + difference;

                console.log(
                    `${difference} milisecond added in flagEventsDuration --now total flagEventsDuration is ${flagEventsDuration}`,
                );

                if (flagEventsDuration >= ProctoringFlagWarningDuration) {
                    this.showProctoringIssueDetectedWarning();
                }

                this.bioSightClient.flagEventsDuration = flagEventsDuration;
                this.bioSightClient.lastEventWasFlagged = false;
                this.bioSightClient.lastEvent = 'flag';
                this.bioSightClient.lastEventTime = currentMilliseconds;
            } else {
                this.bioSightClient.lastEventWasFlagged = true;
                this.bioSightClient.lastEvent = 'flag';
                this.bioSightClient.lastEventTime = currentMilliseconds;
            }
        }
    };

    biosightOkStatusHandler = (latestStatus: { status: string }, self: any) => {
        EventBus.dispatch('biosight-okay');

        if (this.failStatusCheckInterval) {
            this.clearFailStatusCheckInterval();
        }

        if (this.bioSightClient && this.bioSightClient?.isConnected && !this.state.isWarningShownOnce) {
            const currentMilliseconds = Date.now();

            console.log(`current status: ok ${latestStatus?.status} --last status:: ${this.bioSightClient.lastEvent}`);

            if (this.bioSightClient.lastEvent && this.bioSightClient.lastEvent === 'flag') {
                const difference = currentMilliseconds - this.bioSightClient.lastEventTime;
                const flagEventsDuration = (this.bioSightClient.flagEventsDuration ?? 0) + difference;

                console.log(
                    `${difference} milisecond added in flagEventsDuration --now total flagEventsDuration is ${flagEventsDuration}`,
                );

                this.bioSightClient.flagEventsDuration = flagEventsDuration;
                this.bioSightClient.lastEventWasFlagged = false;
                this.bioSightClient.lastEvent = 'okay';
                this.bioSightClient.lastEventTime = currentMilliseconds;
            } else {
                this.bioSightClient.lastEventWasFlagged = false;
                this.bioSightClient.lastEvent = 'okay';
                this.bioSightClient.lastEventTime = currentMilliseconds;
            }
        }
    };

    biosightStartedStatusHandler = (connection: any, sessionId?: string, examId?: string) => {
        // the client is connected

        if (this.bioSightClient) {
            this.bioSightClient.isConnected = true;
        }
        if (this.context.updateCourseInfo) {
            this.context.updateCourseInfo('biosightInfo', {
                isConnected: true,
                message: 'Connected',
                isEvent: false,
                barColor: 'green',
            });
        }
        // @ts-ignore
        this.state?.authCallback?.(sessionId, examId);
        this.setState({ authCallback: () => {} });
    };

    dispatchProctoringConnectionLostModal = () => {
        window.socket.emit('left exam', '');
        EventBus.dispatch('action-confirmation-popup', {
            title: [<div key='title'>Proctoring connection lost</div>],
            body: [
                <div key='body'>This exam requires our proctoring service to run in the background.</div>,
                <ul key='unorder-list'>
                    <li>Reconnect - Proctoring service will be re-initiated to continue your exam.</li>
                    <li>
                        Exit exam - You will re-direct to exam summary & exam timer will continue to run in background.
                    </li>
                </ul>,
            ],
            buttons: [
                {
                    text: `Reconnect`,
                    action: async () => {
                        EventBus.dispatch('re-intialise-proctoring');
                    },
                    clz: 'btn bp',
                },
                {
                    text: 'Exit exam',
                    action: async () => {
                        EventBus.dispatch('exit-exam');
                    },
                    clz: 'btn btn--danger',
                },
            ],
            backdrop: false,
        });
    };

    biosightDataEventHandler = (data: any) => {
        if (data.event) {
            console.log('Event: ' + data.event);
            console.log('Event Detail: ' + data.detail);

            let isConnected = true;
            let isCompleted = false;
            let message = `${data.event} (${data.detail})`;
            let barColor = `green`;

            if (data.event === 'Stop' && data.detail === 'Clicked closed') {
                return;
            } else if (data.event === 'Disconnect') {
                isConnected = false;
                barColor = `orange`;

                if (data.detail === 'Completed') {
                    isCompleted = true;
                    message = 'Completed';
                    barColor = 'gray';
                }

                if (this.bioSightClient && this.bioSightClient.isConnected) {
                    this.bioSightClient.isConnected = false;

                    this.endExamProctoring();
                    this.dispatchProctoringConnectionLostModal();
                }
            } else if (data.event === 'Stop') {
                barColor = 'orange';
                message = 'Stopped';

                console.log('inside stop event this.bioSightClient?.isConnected', this.bioSightClient?.isConnected);
                // if (this.bioSightClient && this.bioSightClient?.isConnected) {
                //     console.log('should i call stop event here ????');
                //     // this.bioSightClient.stop();
                // }
            }

            if (this.context.updateCourseInfo) {
                this.context.updateCourseInfo('biosightInfo', {
                    isConnected: isConnected,
                    isCompleted: isCompleted,
                    isEvent: true,
                    eventInfo: data,
                    message: message,
                    barColor: barColor,
                });
            }
        } else {
            // detailed information about the last video frame analyzed
            // console.log('Detail: Status: ' + data.status); // ok/infraction
            // console.log('Detail: Faces: ' + data.faces.length); // number of faces detected
            // for (const face of data.faces) {
            //     // x/y: the coordinates of the face within a normalized 320x240 frame
            //     // width/height: the size of the detected face
            //     // score: the confidence level between 0-1, note the system internally filters very low confidence levels automatically, and only faces the system deems exceeding this threshold are returned
            //     console.log(face);
            // }
            // console.log('Detail: AudioLevel: ' + data.audioLevel); // the last measured audiolevel from 0-1 (note this is highly microphone specific and should only be considered in terms of abrupt changes)
        }
    };

    biosight = (examId?: string) => {
        console.log('initialise biosight');
        const rurl = process.env.REACT_APP_BIOSIGHT_R_URL
            ? process.env.REACT_APP_BIOSIGHT_R_URL
            : 'https://staging-sandbox.verifyexpress.com';

        let bsiUsername;
        let userFromLocalStorage = localStorage.getItem('user');
        if (userFromLocalStorage) {
            userFromLocalStorage = JSON.parse(userFromLocalStorage);
            // @ts-ignore
            bsiUsername = `REU_${userFromLocalStorage?._id}`;
        }
        // @ts-ignore
        biosightclient.findSession(rurl + '/biosight/findsession', bsiUsername, (s: any) => {
            console.log('biosight sessio s-------------------', s);

            if (s.status !== 'ok') {
                console.error('Cannot find BioSight session!');
            } else {
                // @ts-ignore
                this.bioSightClient = biosightclient(
                    rurl + '/bioSightHub',
                    s.session,
                    // Fail
                    this.biosightFailStatusHandler,
                    // OK
                    this.biosightOkStatusHandler,
                    // started
                    (connection: any) => {
                        this.biosightStartedStatusHandler(connection, s?.session, examId);
                    },
                    // data
                    this.biosightDataEventHandler,
                    // options
                    {
                        loggging: true,
                    },
                );
            }
        });
    };

    render() {
        const { requiresAuth, stage } = this.state;

        if (!requiresAuth) return null;

        return (
            <div className='course-proctoring'>
                <BioSig
                    stage={stage as string}
                    isCheckPoint={this.state.isCheckPoint}
                    isResuming={this.state.isResuming}
                />
            </div>
        );
    }
}

export default withRouterAndRedux(
    Proctoring,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: IUserLoggedIn) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    },
);
