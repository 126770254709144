import React, { Component } from 'react';
import { Api } from 'src/helpers/new';
import CourseContext, { IExamStatus } from './CourseContext';
import { Spinner } from 'src/components/Spinner';

interface IProps {
    courseId: string;
    onCourseLoad: (course: any) => void;
    children?: React.ReactNode;
}

export interface UnlockedNextLessonContent {
    userLessonId: string;
    tooltipMessages: string;
    timeRemaining: number;
}

export interface ICourseChat {
    conversationId: string;
    unreadCount: number;
}
export interface LessonCompletedContent {
    userLessonId: string;
    tooltipMessages: string;
    percentageProgress: number;
    totalLessonCompleted: number;
    courseProgressTime: number;
    passedAssignments?: number;
    totalAssignments?: number;
}

interface IState {
    isLoading: boolean;
    course?: any;
    unlockedLessons: string[];
    completedLessons: string[];
    lessonLayout: any;
    examStatus: IExamStatus;
    unlockNextLessonTooltip: string;
    isSideBarHidden: boolean;
    userCourseConverstion: ICourseChat;
}

export default class CourseManager extends Component<IProps, IState> {
    state: IState = {
        isLoading: true,
        unlockedLessons: [],
        completedLessons: [],
        lessonLayout: null,
        examStatus: {
            available: false,
            message: '',
        },
        unlockNextLessonTooltip: '',
        isSideBarHidden: false,
        userCourseConverstion: {
            conversationId: '',
            unreadCount: 0,
        },
    };

    toggleSideBarHidden = (value: boolean) => {
        this.setState({
            isSideBarHidden: value,
        });
    };

    componentDidMount(): void {
        this.loadCourseData(this.props.courseId);
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.props.courseId !== prevProps.courseId) {
            await this.loadCourseData(this.props.courseId);
        }
    }

    loadCourseData = async (courseId: string) => {
        const response = await this.fetchCourse(courseId);
        if (response !== false) {
            this.setState({
                course: response,
                completedLessons: response.lessons?.completedLessons ?? [],
                unlockedLessons: response.lessons?.unlockedLessons ?? [],
                isLoading: false,
            });
        }
    };

    fetchCourse = async (courseId: string) => {
        const { success, response } = await Api.call('get', `/users/courses/${courseId}`);
        if (success) {
            response.lessonType = response.lessonType ?? 'page';
            delete response.availability;
            delete response.description;
            delete response.edditingAdmins;
            delete response.enrollmentForm;
            this.props.onCourseLoad(response);
            return response;
        }
        return false;
    };

    syncCourseData = async (courseId: string) => {
        const response = await this.fetchCourse(courseId);
        this.setState({
            course: response,
            completedLessons: response.lessons?.completedLessons ?? [],
            unlockedLessons: response.lessons?.unlockedLessons ?? [],
            isLoading: false,
        });
    };

    unlockLesson = (unlockedLessonContent: UnlockedNextLessonContent) => {
        const unlockedLessons = this.state.unlockedLessons;
        if (!unlockedLessons.includes(unlockedLessonContent.userLessonId)) {
            unlockedLessons.push(unlockedLessonContent.userLessonId);
            this.setState({ unlockedLessons, unlockNextLessonTooltip: unlockedLessonContent.tooltipMessages });
        }
    };

    unlockLessonMessages = (unlockedLessonMessage: UnlockedNextLessonContent) => {
        this.setState({ unlockNextLessonTooltip: unlockedLessonMessage.tooltipMessages });
    };

    unlockExam = (examStatus: any) => {
        this.setState({ examStatus: examStatus });
    };

    completeLesson = (lessonCompleted: LessonCompletedContent) => {
        const completedLessons = this.state.completedLessons;
        if (!completedLessons.includes(lessonCompleted.userLessonId)) {
            completedLessons.push(lessonCompleted.userLessonId);
            this.setState({ completedLessons, unlockNextLessonTooltip: lessonCompleted.tooltipMessages });
        }

        if (lessonCompleted.percentageProgress) {
            this.setState((prevState) => {
                return {
                    course: {
                        ...prevState.course,
                        percentageProgress: lessonCompleted.percentageProgress,
                        totalLessonCompleted: lessonCompleted.totalLessonCompleted,
                        passedAssignments: lessonCompleted.passedAssignments,
                        totalAssignments: lessonCompleted.totalAssignments,
                    },
                };
            });
        }

        if (lessonCompleted.courseProgressTime) {
            this.setState((prevState) => {
                return {
                    course: {
                        ...prevState.course,
                        courseProgressTime: lessonCompleted.courseProgressTime,
                    },
                };
            });
        }
    };

    changeExpiresAt = (expiresAt: any) => {
        const course = { ...this.state.course };
        course.expiresAt = expiresAt;
        this.setState({ course: course });
    };

    setCourseConversation = (data: ICourseChat) => {
        this.setState({
            userCourseConverstion: data,
        });
    };

    updateCourseInfo = (key: string, value: string) => {
        const course = { ...this.state.course, [key]: value };
        this.setState({ course: course });
    };

    render() {
        const { course, unlockedLessons, completedLessons, isLoading, examStatus, unlockNextLessonTooltip } =
            this.state;

        if (isLoading) return <Spinner />;

        return (
            <CourseContext.Provider
                value={{
                    course,
                    examStatus,
                    unlockNextLessonTooltip,
                    unlockedLessons: unlockedLessons,
                    completedLessons: completedLessons,
                    isSideBarHidden: this.state.isSideBarHidden,
                    unlockLesson: this.unlockLesson,
                    completeLesson: this.completeLesson,
                    unlockExam: this.unlockExam,
                    syncCourseData: this.syncCourseData,
                    toggleSideBarHidden: this.toggleSideBarHidden,
                    unlockLessonMessages: this.unlockLessonMessages,
                    changeExpiresAt: this.changeExpiresAt,
                    setCourseConversation: this.setCourseConversation,
                    updateCourseInfo: this.updateCourseInfo,
                }}
            >
                {this.props.children}
            </CourseContext.Provider>
        );
    }
}
