import React, { Component } from 'react';
import './MessageContainer.scss';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import NameAvatar from '../Menus/NameAvatar';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { RouteComponentProps } from 'src/hoc/withRouter';
import { IUserLoggedIn } from '../Menus/ProfilePopOver';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

export interface ChatMessage {
    _id?: string;
    chatConversation?: string;
    sender: {
        _id: string;
        firstName: string;
        lastName: string;
        image?: string;
        email?: string;
        file?: {
            fileName: string;
            url: string[];
        };
    };
    message: string;
    timestamp: string;
}

interface IProps extends ChatMessage {
    currentUserId: string;
    loggedIn: IUserLoggedIn;
    isChatPane?: boolean;
}

interface IState {
    showFullMessage: boolean;
    isAvatarHovered: boolean;
}

class MessageContainer extends Component<IProps & RouteComponentProps, IState> {
    state: IState = {
        showFullMessage: false,
        isAvatarHovered: false,
    };

    private hoverTimer: ReturnType<typeof setTimeout> | null = null;

    toggleReadMore = () => {
        this.setState((prevState) => ({
            showFullMessage: !prevState.showFullMessage,
        }));
    };

    parseTruncatedMessage = (str?: string) => {
        if (!str) return '';
        return str.replace(/\n/g, '<br />');
    };

    navigateToProfile = (id: string) => {
        if (id) {
            const url = `/admin/users/${id}`;
            window.open(url, '_blank');
        }
    };

    componentWillUnmount() {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
        }
    }

    handleMouseEnter = () => {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
        }

        if (!this.state.isAvatarHovered) {
            this.hoverTimer = setTimeout(() => {
                this.setState({ isAvatarHovered: true });
            }, 200);
        }
    };

    handleMouseLeave = () => {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
        }

        if (this.state.isAvatarHovered) {
            this.hoverTimer = setTimeout(() => {
                this.setState({ isAvatarHovered: false });
            }, 200);
        }
    };

    renderProfileImage = (
        profileData: {
            file?: { url?: string[] };
            firstName?: string;
            lastName?: string;
            user?: {
                file?: { url?: string[] };
                firstName?: string;
                lastName?: string;
            };
        },
        className = 'profile-photo',
        urlIndex = 2,
    ) => {
        const fileUrl = profileData?.file?.url?.[urlIndex] || profileData?.user?.file?.url?.[urlIndex];
        const firstName = profileData?.firstName ?? profileData?.user?.firstName ?? '';
        const lastName = profileData?.lastName ?? profileData?.user?.lastName ?? '';

        return fileUrl ? (
            <img className={className} src={fileUrl} alt='profile photo' />
        ) : (
            <NameAvatar className={className} firstName={firstName} lastName={lastName} />
        );
    };

    render() {
        const { message, timestamp, sender, currentUserId, loggedIn, isChatPane } = this.props;
        const { showFullMessage, isAvatarHovered } = this.state;
        const truncatedMessage = showFullMessage ? message : message?.slice(0, 200);
        const mouseProps =
            isChatPane === true
                ? {
                      onMouseEnter: this.handleMouseEnter,
                      onMouseLeave: this.handleMouseLeave,
                  }
                : {};
        return (
            <Row className='message-item-container'>
                <Col
                    xs={1}
                    sm={1}
                    md={1}
                    className={`sender-photo ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                    {...mouseProps}
                >
                    {this.renderProfileImage(sender, 'profile-photo')}
                    {isAvatarHovered && isChatPane === true && (
                        <div className='overlay-trigger'>
                            <div className='overlay-content'>
                                <div className='profile-image'>{this.renderProfileImage(sender, 'profile-photo')}</div>
                                <div className='profile-info'>
                                    <div className='name'>
                                        {sender?.firstName} {sender?.lastName}
                                    </div>
                                    <div className='email'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id={`tooltip-sender-email`}>{sender?.email}</Tooltip>}
                                        >
                                            <span>{sender?.email ?? 'No email available'}</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='profile-navigation' onClick={() => this.navigateToProfile(sender._id)}>
                                <i className='fa-solid fa-circle-user' /> <span>View Profile</span>
                            </div>
                        </div>
                    )}
                </Col>
                <Col
                    xs={10}
                    sm={10}
                    md={10}
                    className={`message-container ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                >
                    <p className='sender-name'>{(sender?.firstName ?? '') + ' ' + (sender?.lastName ?? '')}</p>

                    <div
                        className='sender-message'
                        dangerouslySetInnerHTML={{ __html: this.parseTruncatedMessage(truncatedMessage) }}
                    />
                    <div className='bottom-panel'>
                        <div>
                            {!showFullMessage && message.length > 200 && (
                                <span className='read-more' onClick={this.toggleReadMore}>
                                    Read more...
                                </span>
                            )}
                        </div>
                        <div className='timestamp'>{DateTimeFormatHelper.format(timestamp, 'hh:mm A')}</div>
                    </div>
                </Col>
                <Col
                    xs={1}
                    sm={1}
                    md={1}
                    className={`sender-photo-hidden ${sender._id === currentUserId ? 'sender' : 'receiver'}`}
                >
                    {this.renderProfileImage(loggedIn, 'profile-photo', 0)}
                </Col>
            </Row>
        );
    }
}

export default withRouterAndRedux(
    MessageContainer,
    (state: any) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setCourseChat: (payload: any) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    },
);
