import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmationHandler.scss';

type syncAsyncFunction = ((...args: any) => any) | ((...args: any) => Promise<any>);

export interface IConfirationPopup {
    title?: string;
    body?: string | any[];
    doAsyncInBackground?: boolean;
    confirm: {
        text?: string;
        action: syncAsyncFunction;
        ref?: React.RefObject<HTMLButtonElement>;
    } | null;
    cancel?: {
        text?: string;
        action?: syncAsyncFunction;
    } | null;
    dismiss?: syncAsyncFunction;
    backdrop?: boolean;
    className?: string;
}

interface IState {
    modalOpen: boolean;
    modalDetails?: IConfirationPopup;
}

export default class ConfirmationHandler extends Component<unknown, IState> {
    state: IState = {
        modalOpen: false,
    };

    componentDidMount(): void {
        EventBus.on('confirmation-popup', this.createConfirmationPopup);
        EventBus.on('confirmation-popup-close', this.closeModal);
    }

    componentWillUnmount(): void {
        EventBus.remove('confirmation-popup', this.createConfirmationPopup);
        EventBus.remove('confirmation-popup-close', this.closeModal);
    }

    createConfirmationPopup = (e: Event) => {
        const modalDetails: IConfirationPopup = (e as CustomEvent).detail;
        this.setState({ modalOpen: true, modalDetails });
    };

    confirm = async () => {
        if (this.state.modalDetails?.doAsyncInBackground) {
            this.closeModal();
            await this.state.modalDetails?.confirm?.action();
        } else {
            await this.state.modalDetails?.confirm?.action();
            this.closeModal();
        }
    };

    cancel = async () => {
        await this.state.modalDetails?.cancel?.action?.();
        this.closeModal();
    };

    closeModal = () => {
        this.setState({ modalOpen: false, modalDetails: undefined });
    };

    dismiss = async () => {
        if (this.state.modalDetails?.doAsyncInBackground) {
            this.closeModal();
            await this.onDismissHandler();
        } else {
            await this.onDismissHandler();
            this.closeModal();
        }
    };

    onDismissHandler = async () => {
        if (this.state?.modalDetails?.dismiss) {
            await this.state.modalDetails.dismiss();
        } else {
            await this.state.modalDetails?.cancel?.action?.();
        }
    };

    render() {
        const { modalOpen, modalDetails } = this.state;
        const { title, body, cancel, confirm, className, backdrop = true } = modalDetails ?? {};

        return (
            <Modal
                id='confirmation-popup'
                show={modalOpen}
                onHide={this.dismiss}
                className={className}
                backdrop={backdrop}
            >
                {title ? (
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                ) : null}

                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    {cancel !== null && (
                        <Button variant='secondary' onClick={this.cancel}>
                            {cancel?.text ?? 'Cancel'}
                        </Button>
                    )}
                    {confirm !== null && (
                        <Button variant='primary' onClick={this.confirm} ref={confirm?.ref}>
                            {confirm?.text ?? 'Confirm'}
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
